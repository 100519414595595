module.exports = {
  encodeQueryParams: (params) => {
    return Object.keys(params)
      .filter((k) => params[k] !== undefined)
      .filter((k) => params[k] !== null)
      .filter((k) => params[k] !== '')
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&')
  },
}
