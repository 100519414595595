
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import env from '~/mixins/env'
import { openWindow } from '~/utils'

export default {
  mixins: [env],
  data() {
    return {
      openModal: false,
    }
  },

  computed: {
    ...mapState('navigation', ['isOpen']),
    ...mapGetters('user', ['currentUser']),
  },

  watch: {
    '$route.path'(to, from) {
      this.openModal = false
    },
  },

  mounted() {
    window.addEventListener('message', this.onMessage, false)
  },

  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },

  methods: {
    ...mapMutations('navigation', ['openNav', 'closeNav', 'toggleNav']),
    ...mapActions('user', ['logout']),

    async doLogout() {
      const { data } = await this.$axios.post(
        `/logout?provider=${
          this.$auth.$storage.getCookie('provider') ?? 'microsoft'
        }`
      )

      const logoutUrl = data.logoutUrl
      if (logoutUrl) {
        const newWindow = openWindow('', 'Abmelden')
        newWindow.location.href = logoutUrl

        // this is basically a fallback to ensure the window is closed
        setTimeout(async () => {
          console.log('timeout exceeded, closing now')
          newWindow.close()
          await this.finalizeLogout()
        }, 2000)
      } else {
        await this.finalizeLogout()
      }
    },

    async finalizeLogout() {
      await this.logout()
      this.openModal = false
      this.$router.replace('/login')
    },

    async onMessage(e) {
      if (!this.$axios.defaults.baseURL.includes(e.origin)) {
        return
      }

      if (e.data.action !== 'logout') {
        return
      }

      await this.finalizeLogout()
    },

    beforeEnter(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },

    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    afterEnter(el) {
      el.style.opacity = '1'
    },

    beforeLeave(el) {
      el.style.opacity = '1'
      el.style.height = el.scrollHeight + 'px'
    },

    leave(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
  },
}
