export const state = () => ({
  alerts: [],
})

export const actions = {
  raiseAlert({ commit }, { message, state, details, autoHide }) {
    const id = +new Date()
    if (!state) {
      state = 'success'
    }

    if (autoHide === undefined) {
      autoHide = 10000
    }

    commit('addAlert', {
      message,
      state,
      details,
      id,
    })

    if (autoHide) {
      setTimeout(() => {
        commit('removeAlert', id)
      }, autoHide)
    }

    return id
  },
}

export const mutations = {
  addAlert(store, alert) {
    store.alerts.unshift(alert)
  },
  removeAlert(store, id) {
    store.alerts = store.alerts.filter((alert) => alert.id !== id)
  },
}

export const getters = {}
