export default {
  computed: {
    isStaging() {
      return (
        this.$config.nuxtEnv === 'staging' ||
        this.$config.environment === 'staging'
      )
    },

    isDevelopment() {
      return this.$config.nuxtEnv === 'development'
    },
  },
}
