import Vue from 'vue'

export const state = () => ({
  masterData: {},
})

export const actions = {
  async loadAll({ commit }) {
    const { data } = await this.$axios.$get('master-data')

    commit('setMasterData', data)
  },
}

export const mutations = {
  setMasterData(state, data) {
    // Use Vue.set to ensure reactivity
    Vue.set(state, 'masterData', data)
  },
}

export const getters = {
  selectOptions(state) {
    return state.masterData?.options ?? []
  },
}
