module.exports = {
  formatNumber: (value) => {
    const number = Number(value)
    if (isNaN(number)) return value
    if (typeof number !== 'number') return ''
    return number.toLocaleString('de-CH', {
      maximumFractionDigits: 5,
    })
  },
  removeApostroph: (value) => {
    if (typeof value !== 'string') return value

    const number = (10000).toLocaleString('de-CH')
    const match = number.match(/([^0-9])/g)
    const apostroph = match && match[0]
    const regex = new RegExp(`(\\${apostroph})`, 'g')
    return value.replace(regex, '')
  },
}
