
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('navigation', ['pages', 'isOpen']),
    ...mapState('leads', ['customersMeta']),
  },

  mounted() {
    // Load the leads on mount to have the total count
    try {
      this.$store.dispatch('leads/loadAll')
    } catch (e) {
      console.error({ e })
    }
  },

  methods: {
    ...mapMutations('navigation', ['closeNav']),
  },
}
