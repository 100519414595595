
import { mapState } from 'vuex'

export default {
  name: 'DefaultLayout',

  data() {
    return {}
  },

  computed: {
    ...mapState('profiles', ['showAll']),
  },
}
