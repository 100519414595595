
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {}
  },

  computed: {
    ...mapState('alerts', ['alerts']),
  },

  methods: {
    ...mapMutations('alerts', ['removeAlert']),
  },
}
