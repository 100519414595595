export const state = () => ({
  mortgage: null,
})

export const actions = {
  async create({ commit }, { customerId, objectId, data }) {
    const mortgage = await this.$axios.$post(
      `customers/${customerId}/objects/${objectId}/mortgages`,
      data
    )

    commit('setMortgage', mortgage.data)
    return mortgage.data
  },

  async load({ commit }, { customerId, objectId, id }) {
    const { data } = await this.$axios.$get(
      `customers/${customerId}/objects/${objectId}/mortgages/${id}`
    )
    commit('setMortgage', data)
    return data
  },

  async destroy({ commit }, { customerId, objectId, id }) {
    await this.$axios.$delete(
      `customers/${customerId}/objects/${objectId}/mortgages/${id}`
    )
    commit('setMortgage', null)
  },
}

export const mutations = {
  setMortgage(state, value) {
    state.mortgage = value
  },
}

export const getters = {}
