function getUserFromContext(context) {
  return context.$auth.user
}

function can(permissions, context) {
  const userPermissions = getUserFromContext(context)?.permissions ?? []

  if (!permissions) {
    console.warn(
      'can() without a permissions-argument will always evaluate to true'
    )

    return true
  }

  let result = false

  if (Array.isArray(permissions)) {
    result = permissions.some((item) => userPermissions.includes(item))
  } else if (permissions.includes('|')) {
    result = permissions
      .split('|')
      .some((item) => userPermissions.includes(item.trim()))
  } else if (permissions.includes('&')) {
    result = permissions
      .split('&')
      .every((item) => userPermissions.includes(item.trim()))
  } else {
    result = userPermissions.includes(permissions.trim())
  }

  if (!result && process.dev) {
    console.error(
      `can() failed for permission ${permissions} - user permissions: ${userPermissions.join(
        ', '
      )}`
    )
  }

  return result
}

function has(roles, context) {
  const userRoles = getUserFromContext(context)?.roles ?? []

  if (!roles) {
    console.warn('has() without a roles-argument will always evaluate to true')

    return true
  }

  let result = false
  if (Array.isArray(roles)) {
    result = roles.some((item) => userRoles.includes(item))
  } else if (roles.includes('|')) {
    result = roles.split('|').some((item) => userRoles.includes(item.trim()))
  } else if (roles.includes('&')) {
    result = roles.split('&').every((item) => userRoles.includes(item.trim()))
  } else {
    result = userRoles.includes(roles.trim())
  }

  if (!result && process.dev) {
    console.error(
      `has() failed for role ${roles} - user roles: ${userRoles.join(', ')}`
    )
  }

  return result
}

export default (context, inject) => {
  inject('can', function (value) {
    return can(value, context)
  })

  inject('has', function (value) {
    return has(value, context)
  })
}
