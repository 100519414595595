export const state = () => ({})

export const actions = {
  async loadByType({ commit }, type) {
    const { data } = await this.$axios.$get(`/tags/${type}`)

    commit('addTagsForType', { type, tags: data })

    return data
  },
}

export const mutations = {
  addTagsForType(state, { type, tags }) {
    if (state.tags && state.tags[type]) {
      state.tags[type] = [...tags]
    } else {
      state.tags = { [type]: [...tags] }
    }
  },
}

export const getters = {}
