export const state = () => ({
  tranches: null,
})

export const actions = {
  async create({ commit }, { customerId, objectId, mortgageId, data }) {
    const tranche = await this.$axios.$post(
      `customers/${customerId}/objects/${objectId}/mortgages/${mortgageId}/tranches`,
      data
    )
    return tranche.data
  },

  async update({ commit }, { customerId, objectId, mortgageId, id, data }) {
    const tranche = await this.$axios.$patch(
      `customers/${customerId}/objects/${objectId}/mortgages/${mortgageId}/tranches/${id}`,
      data
    )
    return tranche.data
  },

  async destroy({ commit }, { customerId, objectId, mortgageId, id }) {
    await this.$axios.$delete(
      `customers/${customerId}/objects/${objectId}/mortgages/${mortgageId}/tranches/${id}`
    )
  },
}

export const mutations = {
  setTranches(state, value) {
    state.tranches = value
  },
}

export const getters = {}
