import { encodeQueryParams } from '~/utils'

export const state = () => ({
  emailListOptions: [],
  emailTemplateOptions: [],
})

export const actions = {
  async loadEmailListOptions({ commit }, search) {
    const params = {
      search,
    }
    const query = encodeQueryParams(params)

    const { data } = await this.$axios.get(
      `newsletter/lists?filter[search]=${query}&sort=-created_at`
    )
    commit('setEmailListOptions', data.data)
  },

  async createList(_, { name }) {
    const { data } = await this.$axios.post('newsletter/lists', {
      name,
    })

    return data
  },

  async addCustomersToList({ commit }, { listId, customerFilters }) {
    const { data } = await this.$axios.$post(
      `newsletter/lists/${listId}/customers`,
      customerFilters
    )

    return data
  },
}

export const mutations = {
  setEmailListOptions(store, lists) {
    store.emailListOptions = lists
  },
}

export const getters = {
  emailListOptions(state) {
    return state.emailListOptions.map((list) => ({
      name: list.name,
      value: list.id,
    }))
  },
}
