export const state = () => ({})

export const actions = {
  async logout() {
    await this.$auth.logout()
  },

  async load({ commit }) {
    const user = await this.$axios.$get('/api/me')
    commit('setUser', user)
  },

  async fetchOauthUrl(ctx, { provider }) {
    const { data } = await this.$axios.post(`/oauth/${provider}`)

    return data.url
  },

  async setUserToken(state, token) {
    await this.$auth.setUserToken(token)
  },
}

export const mutations = {
  setUser(state, user) {
    state.user = user
  },

  authError(state, error) {
    state.error = error
  },

  setUserAttribute(state, { key, value }) {
    state.user[key] = value
  },

  setUserAttributes(state, datas) {
    datas.forEach((userAttribute) => {
      state.user[userAttribute.key] = userAttribute.value
    })
  },
}

export const getters = {
  currentUser(state, getters, rootState) {
    return rootState.auth.user ?? {}
  },
}
