import {
  getSeconds,
  getMinutes,
  getHours,
  setSeconds,
  setMinutes,
  setHours,
  parse,
  parseISO,
  isAfter,
} from 'date-fns'

export const state = () => ({
  commentInput: '',

  timelineEntries: [],

  timelineState: 'idle',
})

export const actions = {
  async load({ commit }, customerId) {
    const { data } = await this.$axios.$get(`customers/${customerId}/timeline`)

    const sortedEntries = data.sort((a, b) => {
      b = parseISO(b.data?.datetime ?? b.created_at)
      a = parseISO(a.data?.datetime ?? a.created_at)

      return isAfter(a, b) ? -1 : 1
    })

    commit('setTimelineEntries', [])
    commit('setTimelineEntries', sortedEntries)
  },

  async createComment({ dispatch }, { customerId, data }) {
    if (data.date && data.time) {
      let date = data.date
      const parsedTime = parse(data.time, 'HH:mm', date)

      date = setHours(date, getHours(parsedTime))
      date = setMinutes(date, getMinutes(parsedTime))
      date = setSeconds(date, getSeconds(parsedTime))

      data.datetime = date
      delete data.time
      delete data.date
    }

    await this.$axios.$post(`customers/${customerId}/comment`, data)
    await dispatch('load', customerId)
  },

  async destroy({ dispatch }, { customerId, id }) {
    await this.$axios.$delete(`customers/${customerId}/timeline/${id}`)
    await dispatch('load', customerId)
  },
}

export const mutations = {
  setTimelineEntries(state, value) {
    state.timelineEntries = value
  },
  setCommentInput(state, value) {
    state.commentInput = value
  },
  setTimelineState(state, value) {
    state.timelineState = value
  },
}
