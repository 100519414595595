
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      openSearch: false,
      searchString: '',

      customers: [],
      objects: [],
      tranches: [],
      contacts: [],
      loaded: false,
      searchCancelTokenSource: null,
    }
  },

  computed: {
    ...mapGetters('masterData', ['selectOptions']),
    searchDebounce() {
      return debounce(() => this.loadSearchResults(), 300)
    },
  },

  methods: {
    clearSearch() {
      this.searchString = ''
      this.closeSearch()
    },

    closeSearch() {
      this.openSearch = false
    },

    enterSearch() {
      this.openSearch = true
    },

    focusSearch() {
      this.$nextTick(() => {
        this.openSearch = true
        this.$refs.searchInput.focus()
      })
    },

    loadSearchResults() {
      if (this.searchString.length < 3) {
        return
      }

      if (this.searchCancelTokenSource) {
        this.searchCancelTokenSource.cancel()
      }

      this.searchCancelTokenSource = this.$axios.CancelToken.source()

      this.$axios
        .$post(
          'search',
          { search: this.searchString.toLowerCase() },
          {
            cancelToken: this.searchCancelTokenSource.token,
          }
        )
        .then(({ data }) => {
          this.customers = data.customers
          this.objects = data.objects
          this.tranches = data.tranches
          this.contacts = data.contacts
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loaded = true
        })
    },
  },
}
