import Vue from 'vue'
import { parsePhoneNumber } from 'libphonenumber-js'
import { isDate, format, parseISO } from 'date-fns'

function tryFormatDate(date, formatString) {
  if (!date) {
    return ''
  }

  if (!isDate(date)) {
    date = parseISO(date)
  }

  if (!isDate(date)) {
    return ''
  }

  return format(date, formatString)
}

Vue.filter('currency', (value, precision = 0) => {
  value = precision === 0 ? parseInt(value) : parseFloat(value)
  if (typeof value !== 'number') return ''
  return (
    'CHF ' +
    value.toLocaleString('de-CH', {
      maximumFractionDigits: 2,
    })
  )
})

Vue.filter('date', (value) => {
  return tryFormatDate(value, 'dd.MM.yyyy')
})

Vue.filter('time', (value) => {
  return tryFormatDate(value, 'HH:mm')
})

Vue.filter('datetime', (value) => {
  return tryFormatDate(value, 'dd.MM.yyyy, HH:mm')
})

Vue.filter('rate', (value) => {
  const isInt = value % 1 === 0

  if (!isInt) {
    return value.toFixed(2)
  } else {
    return value
  }
})

Vue.filter('phone', (value) => {
  try {
    const parsed = parsePhoneNumber(value)

    if (!parsed) {
      return value
    }

    return parsed.formatInternational()
  } catch (e) {
    console.warn(`error parsing phone number ${value}`, e)
    return value
  }
})
