import * as Sentry from '@sentry/browser'

export default function ({ $auth }) {
  if (process.env.NODE_ENV !== 'production') return

  if ($auth.loggedIn) {
    const { id, email, name } = $auth.user
    const user = {
      id,
      email,
      name,
    }

    if (process.server) {
      process.sentry.setUser(user)
    } else {
      Sentry.setUser(user)
    }
  }
}
