export default ({ store, $auth }) => {
  if ($auth.loggedIn) {
    store.dispatch('masterData/loadAll')
  }

  $auth.$storage.watchState('loggedIn', (isLoggedIn) => {
    if (!isLoggedIn) return
    store.dispatch('masterData/loadAll')
  })
}
