import isEqual from 'lodash/isEqual'
import { format } from 'date-fns'
import { encodeQueryParams } from '~/utils'

let loadAllCancelTokenSource = null
let statisticsCancelTokenSource = null
export const state = () => ({
  filterCustomers: null,
  filterConsultantId: null,
  filterPriorities: null,
  filterTrancheStates: null,
  filterTrancheInstituteId: null,
  filterTrancheInstituteConsultantId: null,
  filterRecruitmentSources: null,
  filterCreation: null,
  filterTrancheDueDate: null,
  filterTrancheValidFrom: null,
  filterTrancheMortgageProducts: null,
  filterTrancheProvisionPaid: null,
  filterCustomerTags: null,
  lastFilter: null,
  statistics: null,
  ordering: {
    column: 'id',
    dir: 'asc', // or 'desc'
  },
  pagination: {
    currentPage: 1,
    lastPage: 1,
  },
  customer: null,
  customers: [],
  customersMeta: {},
  customerOptions: [],
  lastUpdatedCustomers: [],
})

export const actions = {
  async loadAll({ commit, state, getters }) {
    let page = state.pagination.currentPage

    const filter = getters.getFilterParams
    if (!isEqual(state.lastFilter, filter)) {
      page = 1
    }

    const params = {
      page,
      column: state.ordering.column,
      dir: state.ordering.dir,
      ...filter,
    }
    const query = encodeQueryParams(params)

    if (loadAllCancelTokenSource) {
      loadAllCancelTokenSource.cancel()
    }

    loadAllCancelTokenSource = this.$axios.CancelToken.source()

    const { data } = await this.$axios.get(`customers?${query}`, {
      cancelToken: loadAllCancelTokenSource.token,
    })
    commit('setPagination', {
      currentPage: data.meta.current_page,
      lastPage: data.meta.last_page,
    })
    commit('setCustomers', data.data)
    commit('setCustomersMeta', data.meta)
    commit('setLastFilter', filter)
  },

  async load({ commit, state }, customerId) {
    const { data } = await this.$axios.$get(`customers/${customerId}`)
    commit('setCustomer', data)
    return data
  },

  async loadStatistics({ getters, commit, state }) {
    const query = encodeQueryParams(getters.getFilterParams)

    if (statisticsCancelTokenSource) {
      statisticsCancelTokenSource.cancel()
    }

    statisticsCancelTokenSource = this.$axios.CancelToken.source()

    const { data } = await this.$axios.$get(`customers/statistics?${query}`, {
      cancelToken: statisticsCancelTokenSource.token,
    })
    commit('setCustomerStatistics', data)
  },

  save({ dispatch }, { id, data }) {
    const customer = {
      ...data,
      gender: data.gender?.value || null,
      birthdate: data.birthdate ? format(data.birthdate, 'yyyy-MM-dd') : null,
      // Important to do it this way. Because when the recruited_from is undefinded it is not sent in
      // the request. But when it is null, it is sent and gets set null in database.
      recruited_from: data.recruited_from
        ? data.recruited_from.value
        : data.recruited_from,
      phone: data.phone?.replace(/ /g, ''),
      phone_2: data.phone_2?.replace(/ /g, ''),
      mobile: data.mobile?.replace(/ /g, ''),
      tags: data.tags?.map((tag) => tag.label),
    }

    if (data.created_at) {
      customer.created_at = format(data.created_at, 'yyyy-MM-dd')
    }

    if (id) {
      return dispatch('update', {
        id,
        data: customer,
      })
    }

    return dispatch('create', customer)
  },

  async create({ commit }, customer) {
    const { data } = await this.$axios.$post('customers', customer)
    commit('setCustomer', data)
    return data
  },

  async update({ commit }, { id, data }) {
    const customer = await this.$axios.$patch(`customers/${id}`, data)
    commit('setCustomer', customer.data)
    return customer.data
  },

  async archive({ commit }, customerId) {
    await this.$axios.$post(`customers/${customerId}/archive`)
    commit('setCustomer', null)
  },

  async destroy({ commit }, customerId) {
    await this.$axios.$delete(`customers/${customerId}`)
    commit('setCustomer', null)
  },

  async updateOrdering({ commit, dispatch }, ordering) {
    commit('setOrdering', ordering)
    await dispatch('loadAll')
  },

  async changePage({ state, commit, dispatch }, nextPage) {
    const pagination = { ...state.pagination }
    pagination.currentPage = nextPage
    commit('setPagination', pagination)
    await dispatch('loadAll')
  },

  async loadCustomerOptions({ commit }, filters) {
    const params = {
      page: 1,
      ...filters,
    }
    const query = encodeQueryParams(params)

    const { data } = await this.$axios.get(`customers?${query}`)
    commit('setCustomerOptions', data.data)
  },

  async loadLastUpdated({ commit, rootGetters, rootState }, consultantId) {
    const lastUpdatedCustomersQuery = encodeQueryParams({
      page: 1,
      column: 'updated_at',
      dir: 'desc',
      filterConsultantId: consultantId,
      filterVisited: false,
    })

    const lastUpdatedCustomers = await this.$axios.get(
      `customers?${lastUpdatedCustomersQuery}`
    )

    commit('setLastUpdatedCustomers', lastUpdatedCustomers.data.data)
  },

  async destroyDocument({ commit, dispatch }, { customerId, id }) {
    commit('removeDocument', id)

    await this.$axios.$delete(`customers/${customerId}/documents/${id}`)

    dispatch('load', customerId)
  },
}

export const mutations = {
  setCustomers(store, customers) {
    store.customers = customers
  },
  setCustomersMeta(store, meta) {
    store.customersMeta = meta
  },
  setCustomer(store, customer) {
    store.customer = customer
      ? {
          ...customer,
          contacts: customer.contacts?.map((contact) => ({
            ...contact,
            selected: contact.selected || false,
          })),
        }
      : null
  },
  setCustomerStatistics(store, statisticData) {
    store.statistics = statisticData
  },
  setFilterCustomers(state, value) {
    state.filterCustomers = value
  },
  setFilterConsultantId(state, value) {
    state.filterConsultantId = value
  },
  setFilterPriorities(state, value) {
    state.filterPriorities = value
  },
  setFilterTrancheStates(state, value) {
    state.filterTrancheStates = value
  },
  setFilterInstituteId(state, value) {
    state.filterTrancheInstituteId = value
  },
  setFilterInstituteConsultantId(state, value) {
    state.filterTrancheInstituteConsultantId = value
  },
  setFilterRecruitmentSources(state, value) {
    state.filterRecruitmentSources = value
  },
  setFilterCreation(state, value) {
    state.filterCreation = value
  },
  setFilterTrancheDueDate(state, value) {
    state.filterTrancheDueDate = value
  },
  setFilterTrancheValidFrom(state, value) {
    state.filterTrancheValidFrom = value
  },
  setFilterTrancheMortgageProducts(state, value) {
    state.filterTrancheMortgageProducts = value
  },
  setFilterTrancheProvisionPaid(state, value) {
    state.filterTrancheProvisionPaid = value
  },
  setFilterCustomerTags(state, value) {
    state.filterCustomerTags = value
  },
  setOrdering(store, ordering) {
    store.ordering = ordering
  },
  setPagination(store, pagination) {
    store.pagination = pagination
  },
  setCustomerOptions(store, customers) {
    store.customerOptions = customers
  },
  setLastUpdatedCustomers(state, customers) {
    state.lastUpdatedCustomers = customers
  },
  setLastFilter(state, lastFilterObject) {
    state.lastFilter = lastFilterObject
  },

  addDocument(state, document) {
    if (!state.customer) {
      return
    }

    if (!state.customer.documents) {
      state.customer.documents = []
    }
    state.customer.documents.push(document)
  },

  removeDocument(state, documentId) {
    state.customer.documents = state.customer.documents.filter(
      (document) => document.id !== documentId
    )
  },

  setLoadAllCancelTokenSource(state, token) {
    state.loadAllCancelTokenSource = token
  },

  setStatisticsCancelTokenSource(state, token) {
    state.statisticsCancelTokenSource = token
  },
}

export const getters = {
  getFilterParams(state) {
    const filter = {
      filterCustomers: state.filterCustomers,
      filterConsultantId: state.filterConsultantId?.id,
      filterPriorities: state.filterPriorities?.value,
      filterTrancheStates: state.filterTrancheStates?.value,
      filterTrancheInstituteId: state.filterTrancheInstituteId?.value,
      filterTrancheInstituteConsultantId:
        state.filterTrancheInstituteConsultantId?.value,
      filterRecruitmentSources: state.filterRecruitmentSources?.value,
      filterTrancheMortgageProducts: state.filterTrancheMortgageProducts?.value,
      filterTrancheProvisionPaid: state.filterTrancheProvisionPaid?.value,
    }

    if (state.filterCreation?.start) {
      filter.filterCreationStart = format(
        state.filterCreation.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterCreation?.end) {
      filter.filterCreationEnd = format(state.filterCreation?.end, 'yyyy-MM-dd')
    }

    if (state.filterTrancheDueDate?.start) {
      filter.filterTrancheDueDateStart = format(
        state.filterTrancheDueDate.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheDueDate?.end) {
      filter.filterTrancheDueDateEnd = format(
        state.filterTrancheDueDate.end,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheValidFrom?.start) {
      filter.filterTrancheValidFromStart = format(
        state.filterTrancheValidFrom.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheValidFrom?.end) {
      filter.filterTrancheValidFromEnd = format(
        state.filterTrancheValidFrom.end,
        'yyyy-MM-dd'
      )
    }

    if (state.filterCustomerTags?.length > 0) {
      filter.filterCustomerTags = state.filterCustomerTags.map(
        (tag) => tag.slug
      )
    }

    const cleanFilters = Object.fromEntries(
      Object.entries(filter)
        .filter(([_, v]) => v !== undefined)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== '')
    )

    return cleanFilters
  },

  getFilterValues(state) {
    const filter = {
      filterCustomers: state.filterCustomers,
      filterConsultantId: state.filterConsultantId?.name,
      filterPriorities: state.filterPriorities?.label,
      filterTrancheStates: state.filterTrancheStates?.label,
      filterTrancheInstituteId: state.filterTrancheInstituteId?.label,
      filterTrancheInstituteConsultantId:
        state.filterTrancheInstituteConsultantId?.label,
      filterRecruitmentSources: state.filterRecruitmentSources?.label,
      filterTrancheMortgageProducts: state.filterTrancheMortgageProducts?.label,
      filterTrancheProvisionPaid: state.filterTrancheProvisionPaid?.label,
    }

    if (state.filterCreation?.start) {
      filter.filterCreationStart = format(
        state.filterCreation.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterCreation?.end) {
      filter.filterCreationEnd = format(state.filterCreation?.end, 'yyyy-MM-dd')
    }

    if (state.filterTrancheDueDate?.start) {
      filter.filterTrancheDueDateStart = format(
        state.filterTrancheDueDate.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheDueDate?.end) {
      filter.filterTrancheDueDateEnd = format(
        state.filterTrancheDueDate.end,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheValidFrom?.start) {
      filter.filterTrancheValidFromStart = format(
        state.filterTrancheValidFrom.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheValidFrom?.end) {
      filter.filterTrancheValidFromEnd = format(
        state.filterTrancheValidFrom.end,
        'yyyy-MM-dd'
      )
    }

    if (state.filterCustomerTags?.length > 0) {
      filter.filterCustomerTags = state.filterCustomerTags
        .map((tag) => tag.slug)
        .join(', ')
    }

    const cleanFilters = Object.fromEntries(
      Object.entries(filter)
        .filter(([_, v]) => v !== undefined)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== '')
    )

    return cleanFilters
  },

  customerOptions(state) {
    return state.customerOptions.map((customer) => ({
      label: customer.name,
      name: customer.name,
      value: customer.id,
      id: customer.id,
    }))
  },
  customersWithNewRequests: (state) => state.customersWithNewRequests,
  customersWithOpenRequests: (state) => state.customersWithOpenRequests,
  customer: (state) => state.customer,
  lastUpdatedCustomers: (state) => state.lastUpdatedCustomers,
  customers: (state) => state.customers,
}
