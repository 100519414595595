import {
  isBefore,
  startOfDay,
  isSameDay,
  parseISO,
  differenceInDays,
  addDays,
  isAfter,
} from 'date-fns'

export const state = () => ({
  reminders: [],
  personalReminders: [],
  customerReminders: [],
  cancelTokenSource: null,
})

export const actions = {
  async loadAll({ commit, state, getters }, userId) {
    let url = ''

    if (userId) {
      url = `users/${userId}/reminders`
    } else {
      url = 'reminders'
    }

    // Cancel the previous request if it exists
    if (state.cancelTokenSource) {
      state.cancelTokenSource.cancel('Operation canceled due to new request.')
    }

    // Create a new CancelToken source
    const cancelTokenSource = this.$axios.CancelToken.source()
    commit('setCancelTokenSource', cancelTokenSource)

    let response = null
    try {
      response = await this.$axios.get(url, {
        cancelToken: cancelTokenSource.token, // Pass the cancelToken here
      })

      // Reset the cancel token source
      commit('setCancelTokenSource', null)
    } catch (error) {
      if (this.$axios.isCancel(error)) {
        console.info('Request was canceled:', error.message)
      } else {
        console.error('Request failed:', error)
      }

      // Ensure cancel token is reset even in case of error
      commit('setCancelTokenSource', null)
    } finally {
      commit('setReminders', response?.data?.data || [])
    }
  },

  async loadPersonalReminders({ commit, rootGetters }) {
    const { data } = await this.$axios.$get(
      `users/${rootGetters['user/currentUser'].id}/reminders`
    )
    commit('setPersonalReminders', data)
  },

  async create({ commit }, { userId, data }) {
    const reminder = await this.$axios.$post(`users/${userId}/reminders`, data)
    return reminder.data
  },

  async update({ dispatch }, { userId, id, data }) {
    const reminder = await this.$axios.$patch(
      `users/${userId}/reminders/${id}`,
      data
    )
    return reminder.data
  },

  async loadCustomerReminders({ commit }, customerId) {
    const { data } = await this.$axios.$get(`customers/${customerId}/reminders`)

    commit('setCustomerReminders', data)
  },
}

export const mutations = {
  setReminders(state, value) {
    state.reminders = value
  },
  setPersonalReminders(state, value) {
    state.personalReminders = value
  },
  setCustomerReminders(state, value) {
    state.customerReminders = value
  },
  setCancelTokenSource(state, cancelTokenSource) {
    state.cancelTokenSource = cancelTokenSource
  },
}

export const getters = {
  nowReminders(state) {
    return state.reminders.filter((reminder) => {
      const remindOn = parseISO(reminder.remind_on)
      const isBeforeToday = isBefore(remindOn, startOfDay(new Date()))
      const isToday = isSameDay(new Date(), remindOn)

      return (isToday || isBeforeToday) && !reminder.is_done
    })
  },

  soonReminders(state) {
    return state.reminders.filter((reminder) => {
      const remindOnDate = parseISO(reminder.remind_on)
      const diffDays = differenceInDays(
        startOfDay(remindOnDate),
        startOfDay(new Date())
      )
      const tomorrow = startOfDay(addDays(new Date(), 1))
      const isTomorrow = isAfter(remindOnDate, tomorrow)

      return isTomorrow && diffDays <= 7 && !reminder.is_done
    })
  },

  laterReminders(state) {
    return state.reminders.filter((reminder) => {
      const remindOnDate = parseISO(reminder.remind_on)
      const diffDays = differenceInDays(
        startOfDay(remindOnDate),
        startOfDay(new Date())
      )

      return diffDays > 7 && !reminder.is_done
    })
  },
}
