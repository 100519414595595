
export default {
  props: {
    state: {
      type: String,
      default: 'info',
    },
  },

  data() {
    return {}
  },

  computed: {
    iconName() {
      switch (this.state) {
        case 'success':
          return ['fas', 'check']
        case 'info':
          return ['fas', 'rocket']
        case 'error':
          return ['fas', 'exclamation-circle']
        case 'warning':
          return ['fas', 'exclamation-triangle']
        default:
          return ['fas', 'rocket']
      }
    },
  },
}
