
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      showReminderModal: false,
      reminderIntervalHandle: null,
    }
  },

  computed: {
    ...mapState('reminders', ['personalReminders']),
    currentReminders() {
      return this.personalReminders.filter((reminder) => {
        const diffMinutes = this.$dateFns.differenceInMinutes(
          this.$dateFns.parseISO(reminder.remind_on),
          new Date()
        )

        return diffMinutes <= 15 && !reminder.is_done
      })
    },
  },

  mounted() {
    this.loadPersonalReminders()
    this.reminderIntervalHandle = setInterval(
      this.loadPersonalReminders,
      1000 * 30
    )
  },

  beforeDestroy() {
    clearInterval(this.reminderIntervalHandle)
  },

  methods: {
    ...mapActions('reminders', ['loadPersonalReminders']),
    closeReminderModal() {
      this.showReminderModal = false
    },
  },
}
