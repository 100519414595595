
export default {
  layout({ store }) {
    return store?.state?.lastErrorStatusCode === 503 ? 'empty' : 'default'
  },

  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  head() {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0',
        },
      ],
    }
  },

  computed: {
    statusCode() {
      return this.error?.statusCode || 500
    },

    message() {
      if (this.statusCode === 404) {
        return 'Diese Seite existiert nicht.'
      } else if (this.statusCode === 403) {
        return 'Sie haben kein Zugriff auf diese Seite.'
      } else if (this.statusCode === 422) {
        return 'Es wurden invalide Daten übermittelt. Überprüfen Sie die Eingabe.'
      } else if (this.statusCode === 429) {
        return 'Die Zugriffslimite für diese Seite wurde überschritten.<br/>Versuchen Sie es später noch einmal.'
      } else if (this.statusCode === 503) {
        return 'Die Applikation wird momentan gewartet. <br/> Bitte versuchen Sie es später noch einmal.'
      }

      return 'Es ist ein unerwarteter Fehler aufgetreten.'
    },
  },
}
