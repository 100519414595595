export const state = () => ({
  rate: '',
  type: '',
  duration: '',

  interests: [],
})

export const actions = {
  async loadAll({ commit }) {
    const { data } = await this.$axios.get('interest-rates')

    commit('setInterests', data.data)
  },

  save({ dispatch }, { id, data }) {
    const formData = {
      ...data,
      duration_type: data.duration_type?.value,
      mortgage_product: data.mortgage_product?.value,
    }
    if (id) {
      return dispatch('updateInterest', {
        id,
        data: formData,
      })
    } else {
      return dispatch('createInterest', formData)
    }
  },

  async createInterest({ dispatch }, formData) {
    try {
      await this.$axios.post('interest-rates', formData)
      dispatch('loadAll')
    } catch (error) {
      console.error(error)
    }
  },

  async updateInterest({ dispatch }, { id, data }) {
    try {
      await this.$axios.patch(`interest-rates/${id}`, data)
      dispatch('loadAll')
    } catch (error) {
      console.error(error)
    }
  },

  async destroyInterest({ dispatch }, id) {
    await this.$axios.delete(`interest-rates/${id}`)
    dispatch('loadAll')
  },
}

export const mutations = {
  setRate(state, value) {
    state.rate = value
  },

  setType(state, value) {
    state.type = value
  },

  setDuration(state, value) {
    state.duration = value
  },

  setInterests(state, interests) {
    state.interests = interests
  },
}
