export default function ({ $axios, error: nuxtError, store }) {
  $axios.onError((e) => {
    const code = parseInt(e.response?.status)
    store.commit('setLastErrorStatusCode', e.response?.status)

    if (code === 503) {
      nuxtError({ statusCode: 503 })
      // https://axios.nuxtjs.org/helpers/#interceptors
      return Promise.resolve(false)
    }

    if (code === 403 && process.server) {
      nuxtError({ statusCode: 403 })
      // https://axios.nuxtjs.org/helpers/#interceptors
      return Promise.resolve(false)
    }

    if (code === 400 && process.server) {
      nuxtError({ statusCode: 400 })
      // https://axios.nuxtjs.org/helpers/#interceptors
      return Promise.resolve(false)
    }
  })
}
