import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f5d9af22 = () => interopDefault(import('../pages/institute.vue' /* webpackChunkName: "pages/institute" */))
const _3c3911bb = () => interopDefault(import('../pages/kundenliste/index.vue' /* webpackChunkName: "pages/kundenliste/index" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _afebbec6 = () => interopDefault(import('../pages/mailcoach.vue' /* webpackChunkName: "pages/mailcoach" */))
const _6f30432a = () => interopDefault(import('../pages/neue-leads/index.vue' /* webpackChunkName: "pages/neue-leads/index" */))
const _28d88f9c = () => interopDefault(import('../pages/zinssaetze.vue' /* webpackChunkName: "pages/zinssaetze" */))
const _385fecc7 = () => interopDefault(import('../pages/kundenliste/neu.vue' /* webpackChunkName: "pages/kundenliste/neu" */))
const _a739750e = () => interopDefault(import('../pages/neue-leads/scores.vue' /* webpackChunkName: "pages/neue-leads/scores" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _575c3854 = () => interopDefault(import('../pages/kontakte/_slug.vue' /* webpackChunkName: "pages/kontakte/_slug" */))
const _3a827073 = () => interopDefault(import('../pages/kundenliste/_slug.vue' /* webpackChunkName: "pages/kundenliste/_slug" */))
const _06d5f9cd = () => interopDefault(import('../pages/kundenliste/_customer/kontakte/neu.vue' /* webpackChunkName: "pages/kundenliste/_customer/kontakte/neu" */))
const _2dcfe098 = () => interopDefault(import('../pages/kundenliste/_customer/objekte/neu.vue' /* webpackChunkName: "pages/kundenliste/_customer/objekte/neu" */))
const _c484e7c0 = () => interopDefault(import('../pages/kundenliste/_customer/objekte/_slug.vue' /* webpackChunkName: "pages/kundenliste/_customer/objekte/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/institute",
    component: _f5d9af22,
    name: "institute"
  }, {
    path: "/kundenliste",
    component: _3c3911bb,
    name: "kundenliste"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/mailcoach",
    component: _afebbec6,
    name: "mailcoach"
  }, {
    path: "/neue-leads",
    component: _6f30432a,
    name: "neue-leads"
  }, {
    path: "/zinssaetze",
    component: _28d88f9c,
    name: "zinssaetze"
  }, {
    path: "/kundenliste/neu",
    component: _385fecc7,
    name: "kundenliste-neu"
  }, {
    path: "/neue-leads/scores",
    component: _a739750e,
    name: "neue-leads-scores"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/kontakte/:slug?",
    component: _575c3854,
    name: "kontakte-slug"
  }, {
    path: "/kundenliste/:slug",
    component: _3a827073,
    name: "kundenliste-slug"
  }, {
    path: "/kundenliste/:customer/kontakte/neu",
    component: _06d5f9cd,
    name: "kundenliste-customer-kontakte-neu"
  }, {
    path: "/kundenliste/:customer/objekte/neu",
    component: _2dcfe098,
    name: "kundenliste-customer-objekte-neu"
  }, {
    path: "/kundenliste/:customer/objekte/:slug?",
    component: _c484e7c0,
    name: "kundenliste-customer-objekte-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
