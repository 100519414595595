export const OAuthLogin = () => import('../../components/auth/OAuthLogin.vue' /* webpackChunkName: "components/o-auth-login" */).then(c => wrapFunctional(c.default || c))
export const AddTranche = () => import('../../components/cards/AddTranche.vue' /* webpackChunkName: "components/add-tranche" */).then(c => wrapFunctional(c.default || c))
export const ContactCard = () => import('../../components/cards/ContactCard.vue' /* webpackChunkName: "components/contact-card" */).then(c => wrapFunctional(c.default || c))
export const CustomerCard = () => import('../../components/cards/CustomerCard.vue' /* webpackChunkName: "components/customer-card" */).then(c => wrapFunctional(c.default || c))
export const CustomerInformation = () => import('../../components/cards/CustomerInformation.vue' /* webpackChunkName: "components/customer-information" */).then(c => wrapFunctional(c.default || c))
export const DocumentCard = () => import('../../components/cards/DocumentCard.vue' /* webpackChunkName: "components/document-card" */).then(c => wrapFunctional(c.default || c))
export const EditTranche = () => import('../../components/cards/EditTranche.vue' /* webpackChunkName: "components/edit-tranche" */).then(c => wrapFunctional(c.default || c))
export const InstituteCard = () => import('../../components/cards/InstituteCard.vue' /* webpackChunkName: "components/institute-card" */).then(c => wrapFunctional(c.default || c))
export const InterestCard = () => import('../../components/cards/InterestCard.vue' /* webpackChunkName: "components/interest-card" */).then(c => wrapFunctional(c.default || c))
export const LeadCard = () => import('../../components/cards/LeadCard.vue' /* webpackChunkName: "components/lead-card" */).then(c => wrapFunctional(c.default || c))
export const MortgageCard = () => import('../../components/cards/MortgageCard.vue' /* webpackChunkName: "components/mortgage-card" */).then(c => wrapFunctional(c.default || c))
export const NewElementCard = () => import('../../components/cards/NewElementCard.vue' /* webpackChunkName: "components/new-element-card" */).then(c => wrapFunctional(c.default || c))
export const ObjectCard = () => import('../../components/cards/ObjectCard.vue' /* webpackChunkName: "components/object-card" */).then(c => wrapFunctional(c.default || c))
export const ReminderCard = () => import('../../components/cards/ReminderCard.vue' /* webpackChunkName: "components/reminder-card" */).then(c => wrapFunctional(c.default || c))
export const ScoreCard = () => import('../../components/cards/ScoreCard.vue' /* webpackChunkName: "components/score-card" */).then(c => wrapFunctional(c.default || c))
export const StatCard = () => import('../../components/cards/StatCard.vue' /* webpackChunkName: "components/stat-card" */).then(c => wrapFunctional(c.default || c))
export const AddAccordion = () => import('../../components/customer/AddAccordion.vue' /* webpackChunkName: "components/add-accordion" */).then(c => wrapFunctional(c.default || c))
export const AddButton = () => import('../../components/customer/AddButton.vue' /* webpackChunkName: "components/add-button" */).then(c => wrapFunctional(c.default || c))
export const AddLink = () => import('../../components/customer/AddLink.vue' /* webpackChunkName: "components/add-link" */).then(c => wrapFunctional(c.default || c))
export const AddModal = () => import('../../components/customer/AddModal.vue' /* webpackChunkName: "components/add-modal" */).then(c => wrapFunctional(c.default || c))
export const AddRelatedCustomer = () => import('../../components/customer/AddRelatedCustomer.vue' /* webpackChunkName: "components/add-related-customer" */).then(c => wrapFunctional(c.default || c))
export const BoxAccordion = () => import('../../components/customer/BoxAccordion.vue' /* webpackChunkName: "components/box-accordion" */).then(c => wrapFunctional(c.default || c))
export const CheckDuplicateContacts = () => import('../../components/customer/CheckDuplicateContacts.vue' /* webpackChunkName: "components/check-duplicate-contacts" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/customer/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactGoToCustomer = () => import('../../components/customer/ContactGoToCustomer.vue' /* webpackChunkName: "components/contact-go-to-customer" */).then(c => wrapFunctional(c.default || c))
export const ContactRelations = () => import('../../components/customer/ContactRelations.vue' /* webpackChunkName: "components/contact-relations" */).then(c => wrapFunctional(c.default || c))
export const CreateCustomerFromContact = () => import('../../components/customer/CreateCustomerFromContact.vue' /* webpackChunkName: "components/create-customer-from-contact" */).then(c => wrapFunctional(c.default || c))
export const CreateReminder = () => import('../../components/customer/CreateReminder.vue' /* webpackChunkName: "components/create-reminder" */).then(c => wrapFunctional(c.default || c))
export const CustomerForm = () => import('../../components/customer/CustomerForm.vue' /* webpackChunkName: "components/customer-form" */).then(c => wrapFunctional(c.default || c))
export const CustomerHeader = () => import('../../components/customer/CustomerHeader.vue' /* webpackChunkName: "components/customer-header" */).then(c => wrapFunctional(c.default || c))
export const CustomerResume = () => import('../../components/customer/CustomerResume.vue' /* webpackChunkName: "components/customer-resume" */).then(c => wrapFunctional(c.default || c))
export const DuplicateConfirmationModal = () => import('../../components/customer/DuplicateConfirmationModal.vue' /* webpackChunkName: "components/duplicate-confirmation-modal" */).then(c => wrapFunctional(c.default || c))
export const DuplicateContactItem = () => import('../../components/customer/DuplicateContactItem.vue' /* webpackChunkName: "components/duplicate-contact-item" */).then(c => wrapFunctional(c.default || c))
export const MortgageSection = () => import('../../components/customer/MortgageSection.vue' /* webpackChunkName: "components/mortgage-section" */).then(c => wrapFunctional(c.default || c))
export const NewsletterListModal = () => import('../../components/customer/NewsletterListModal.vue' /* webpackChunkName: "components/newsletter-list-modal" */).then(c => wrapFunctional(c.default || c))
export const ObjectForm = () => import('../../components/customer/ObjectForm.vue' /* webpackChunkName: "components/object-form" */).then(c => wrapFunctional(c.default || c))
export const ObjectHeader = () => import('../../components/customer/ObjectHeader.vue' /* webpackChunkName: "components/object-header" */).then(c => wrapFunctional(c.default || c))
export const ObjectResume = () => import('../../components/customer/ObjectResume.vue' /* webpackChunkName: "components/object-resume" */).then(c => wrapFunctional(c.default || c))
export const ObjectSection = () => import('../../components/customer/ObjectSection.vue' /* webpackChunkName: "components/object-section" */).then(c => wrapFunctional(c.default || c))
export const ReminderModal = () => import('../../components/customer/ReminderModal.vue' /* webpackChunkName: "components/reminder-modal" */).then(c => wrapFunctional(c.default || c))
export const SendTransactionalMail = () => import('../../components/customer/SendTransactionalMail.vue' /* webpackChunkName: "components/send-transactional-mail" */).then(c => wrapFunctional(c.default || c))
export const TrancheForm = () => import('../../components/customer/TrancheForm.vue' /* webpackChunkName: "components/tranche-form" */).then(c => wrapFunctional(c.default || c))
export const TransactionalMailModal = () => import('../../components/customer/TransactionalMailModal.vue' /* webpackChunkName: "components/transactional-mail-modal" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/forms/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const CalendarRange = () => import('../../components/forms/CalendarRange.vue' /* webpackChunkName: "components/calendar-range" */).then(c => wrapFunctional(c.default || c))
export const FormBox = () => import('../../components/forms/FormBox.vue' /* webpackChunkName: "components/form-box" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../components/forms/FormCheckbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/forms/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormTextarea = () => import('../../components/forms/FormTextarea.vue' /* webpackChunkName: "components/form-textarea" */).then(c => wrapFunctional(c.default || c))
export const SelectBox = () => import('../../components/forms/SelectBox.vue' /* webpackChunkName: "components/select-box" */).then(c => wrapFunctional(c.default || c))
export const SelectNoLine = () => import('../../components/forms/SelectNoLine.vue' /* webpackChunkName: "components/select-no-line" */).then(c => wrapFunctional(c.default || c))
export const TagMultiselect = () => import('../../components/forms/TagMultiselect.vue' /* webpackChunkName: "components/tag-multiselect" */).then(c => wrapFunctional(c.default || c))
export const ArrowDown = () => import('../../components/icons/ArrowDown.vue' /* webpackChunkName: "components/arrow-down" */).then(c => wrapFunctional(c.default || c))
export const Clear = () => import('../../components/icons/Clear.vue' /* webpackChunkName: "components/clear" */).then(c => wrapFunctional(c.default || c))
export const DisabledUserTie = () => import('../../components/icons/DisabledUserTie.vue' /* webpackChunkName: "components/disabled-user-tie" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/icons/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoLight = () => import('../../components/icons/LogoLight.vue' /* webpackChunkName: "components/logo-light" */).then(c => wrapFunctional(c.default || c))
export const NovuBadge = () => import('../../components/icons/NovuBadge.vue' /* webpackChunkName: "components/novu-badge" */).then(c => wrapFunctional(c.default || c))
export const UserTie = () => import('../../components/icons/UserTie.vue' /* webpackChunkName: "components/user-tie" */).then(c => wrapFunctional(c.default || c))
export const ActionButtons = () => import('../../components/navigation/ActionButtons.vue' /* webpackChunkName: "components/action-buttons" */).then(c => wrapFunctional(c.default || c))
export const MainNav = () => import('../../components/navigation/MainNav.vue' /* webpackChunkName: "components/main-nav" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/navigation/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SiteNavigation = () => import('../../components/navigation/SiteNavigation.vue' /* webpackChunkName: "components/site-navigation" */).then(c => wrapFunctional(c.default || c))
export const ListHeader = () => import('../../components/pagespecific/ListHeader.vue' /* webpackChunkName: "components/list-header" */).then(c => wrapFunctional(c.default || c))
export const ListHeaderLeads = () => import('../../components/pagespecific/ListHeaderLeads.vue' /* webpackChunkName: "components/list-header-leads" */).then(c => wrapFunctional(c.default || c))
export const WelcomeBanner = () => import('../../components/pagespecific/WelcomeBanner.vue' /* webpackChunkName: "components/welcome-banner" */).then(c => wrapFunctional(c.default || c))
export const Action = () => import('../../components/timeline/Action.vue' /* webpackChunkName: "components/action" */).then(c => wrapFunctional(c.default || c))
export const BaseEntry = () => import('../../components/timeline/BaseEntry.vue' /* webpackChunkName: "components/base-entry" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/timeline/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const CommentInput = () => import('../../components/timeline/CommentInput.vue' /* webpackChunkName: "components/comment-input" */).then(c => wrapFunctional(c.default || c))
export const LeadInfo = () => import('../../components/timeline/LeadInfo.vue' /* webpackChunkName: "components/lead-info" */).then(c => wrapFunctional(c.default || c))
export const Mail = () => import('../../components/timeline/Mail.vue' /* webpackChunkName: "components/mail" */).then(c => wrapFunctional(c.default || c))
export const Reminder = () => import('../../components/timeline/Reminder.vue' /* webpackChunkName: "components/reminder" */).then(c => wrapFunctional(c.default || c))
export const ReminderList = () => import('../../components/timeline/ReminderList.vue' /* webpackChunkName: "components/reminder-list" */).then(c => wrapFunctional(c.default || c))
export const TimeBoxEntry = () => import('../../components/timeline/TimeBoxEntry.vue' /* webpackChunkName: "components/time-box-entry" */).then(c => wrapFunctional(c.default || c))
export const TimelineBox = () => import('../../components/timeline/TimelineBox.vue' /* webpackChunkName: "components/timeline-box" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/ui/Alerts.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/ui/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const BadgeSelect = () => import('../../components/ui/BadgeSelect.vue' /* webpackChunkName: "components/badge-select" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/ui/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/ui/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/ui/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const Date = () => import('../../components/ui/Date.vue' /* webpackChunkName: "components/date" */).then(c => wrapFunctional(c.default || c))
export const LineAccordion = () => import('../../components/ui/LineAccordion.vue' /* webpackChunkName: "components/line-accordion" */).then(c => wrapFunctional(c.default || c))
export const LogoLightBtn = () => import('../../components/ui/LogoLightBtn.vue' /* webpackChunkName: "components/logo-light-btn" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/ui/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const PageNav = () => import('../../components/ui/PageNav.vue' /* webpackChunkName: "components/page-nav" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/ui/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const SelectOption = () => import('../../components/ui/SelectOption.vue' /* webpackChunkName: "components/select-option" */).then(c => wrapFunctional(c.default || c))
export const StdAlert = () => import('../../components/ui/StdAlert.vue' /* webpackChunkName: "components/std-alert" */).then(c => wrapFunctional(c.default || c))
export const ToggleButton = () => import('../../components/ui/ToggleButton.vue' /* webpackChunkName: "components/toggle-button" */).then(c => wrapFunctional(c.default || c))
export const WhiteBox = () => import('../../components/ui/WhiteBox.vue' /* webpackChunkName: "components/white-box" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
