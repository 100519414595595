export const state = () => ({
  isOpen: false,

  pages: [
    {
      title: 'Dashboard',
      url: '/',
      permissions: ['dashboard.view'],
    },
    {
      title: 'Neue Leads',
      url: '/neue-leads',
      permissions: ['customer.view-leads'],
    },
    {
      title: 'Kundenliste',
      url: '/kundenliste',
      permissions: ['customer.view-any'],
    },
    {
      title: 'Zinssätze',
      url: '/zinssaetze',
      permissions: ['interest-rate.view-any'],
    },
    {
      title: 'Institute',
      url: '/institute',
      permissions: ['institute.view-any'],
    },
    {
      title: 'Newsletter',
      url: '/mailcoach',
      external: true,
      permissions: ['newsletter.view'],
    },
  ],
})

export const mutations = {
  openNav(state) {
    state.isOpen = true
  },

  closeNav(state) {
    state.isOpen = false
  },

  toggleNav(state) {
    state.isOpen = !state.isOpen
  },
}
