import { encodeQueryParams } from '~/utils'

export const state = () => ({
  newCreatedElements: [],
  newCreatedElementsMeta: {},
  newCreatedPage: 1,
})

export const actions = {
  async loadNewCreated({ commit, dispatch }, consultantId) {
    const data = await dispatch('getNewCreatedElements', {
      filters: { page: 1 },
      consultantId,
    })

    commit('setNewCreatedElements', data)
  },
  async loadMoreNewCreated({ dispatch, getters, commit }, consultantId) {
    const data = await dispatch('getNewCreatedElements', {
      filters: { page: getters.newCreatedElementsMeta.current_page + 1 },
      consultantId,
    })

    commit('setNewCreatedElements', [...getters.newCreatedElements, ...data])
  },
  async getNewCreatedElements({ commit }, { filters, consultantId }) {
    const newCreatedQuery = encodeQueryParams({
      consultantId,
      ...filters,
    })
    const { data, meta } = await this.$axios.$get(
      `dashboard/new-created?${newCreatedQuery}`
    )
    commit('setNewCreatedElementsMeta', meta)
    return data
  },
  updateCustomerInStore({ getters, commit, rootGetters }, newCustomer) {
    const newElements = getters.newCreatedElements.map((element) => {
      if (element.customer.id === newCustomer.id) {
        return {
          ...element,
          customer: newCustomer,
        }
      }
      return element
    })
    commit('setNewCreatedElements', newElements)

    const lastUpdatedCustomers = rootGetters[
      'customers/lastUpdatedCustomers'
    ].map((customer) => {
      if (customer.id === newCustomer.id) return newCustomer
      return customer
    })
    commit('customers/setLastUpdatedCustomers', lastUpdatedCustomers, {
      root: true,
    })
  },
}

export const mutations = {
  setNewCreatedElements(state, newCreatedElements) {
    state.newCreatedElements = newCreatedElements
  },
  setNewCreatedElementsMeta(state, meta) {
    state.newCreatedElementsMeta = meta
  },
}

export const getters = {
  newCreatedElements: (state) =>
    state.newCreatedElements.map((element) => ({
      ...element,
      customer:
        element.customer ||
        element.element.mortgage?.object.customer ||
        element.element.customer ||
        element.element,
    })),
  newCreatedPage: (state) => state.newCreatedPage,
  newCreatedElementsMeta: (state) => state.newCreatedElementsMeta,
}
