export const state = () => ({
  lastErrorStatusCode: null,
})

export const actions = {
  async nuxtServerInit({ dispatch }) {
    // ..
  },
}

export const mutations = {
  setLastErrorStatusCode(state, lastErrorStatusCode) {
    state.lastErrorStatusCode = lastErrorStatusCode
  },
}

export const getters = {}
