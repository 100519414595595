import { encodeQueryParams } from '~/utils'

export const state = () => ({
  emailListOptions: [],
  emailTemplateOptions: [],
})

export const actions = {
  async loadEmailTemplateOptions({ commit }, { search, customerId }) {
    const params = {
      search,
      customerId,
    }
    const query = encodeQueryParams(params)

    const { data } = await this.$axios.get(
      `transaction-mails/templates?${query}`
    )
    commit('setEmailTemplateOptions', data.data)
  },

  async sendMail({ commit }, { templateId, mailForm }) {
    const { data } = await this.$axios.post(
      `transaction-mails/templates/${templateId}/send`,
      mailForm,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data
  },
}

export const mutations = {
  setEmailTemplateOptions(store, templates) {
    store.emailTemplateOptions = templates
  },
}

export const getters = {
  emailTemplateOptions(state) {
    return state.emailTemplateOptions.map((template) => ({
      name: template.name,
      value: template.id,
      body: template.body,
      subject: template.subject,
      attachments: template.attachments,
    }))
  },
}
