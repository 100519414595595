export const state = () => ({
  institutes: [],
})

export const actions = {
  async loadAll({ commit }) {
    const { data } = await this.$axios.get('institutes')

    commit('setInstitutes', data.data)
  },

  async save({ dispatch }, form) {
    if (form.id) {
      await dispatch('updateInstitute', form)
    } else {
      await dispatch('createInstitute', form)
    }

    await dispatch('loadAll')
  },

  async createInstitute({ dispatch }, form) {
    try {
      await this.$axios.$post('institutes', form)

      await dispatch('loadAll')
    } catch (error) {
      console.error(error)
    }
  },

  async updateInstitute({ dispatch }, form) {
    try {
      delete form.documents
      await this.$axios.patch(`institutes/${form.id}`, form)

      dispatch('loadAll')
    } catch (error) {
      console.error(error)
    }
  },

  async destroyDocument({ commit, dispatch }, { instituteId, id }) {
    await this.$axios.$delete(`institutes/${instituteId}/documents/${id}`)

    dispatch('loadAll')
  },

  async destroyInstitute({ dispatch }, id) {
    await this.$axios.delete(`institutes/${id}`)
    dispatch('loadAll')
  },

  async attachFiles({ dispatch }, { group, instituteId, files }) {
    try {
      for (const file of files) {
        const formData = new FormData()
        formData.append('file', file)
        await this.$axios.post(
          `institutes/${instituteId}/documents/${group}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
      }
    } catch (error) {
      console.error(error)
    }
  },
}

export const mutations = {
  setInstitutes(state, institute) {
    state.institutes = institute
  },
}

export const getters = {
  institutes(state) {
    return state.institutes
  },

  instituteOptions(state) {
    return state.institutes.map((institute) => ({
      value: institute.id,
      label: institute.name,
      consultants: institute.consultants.map((consultant) => ({
        value: consultant.id,
        label: `${consultant.first_name} ${consultant.last_name}`,
      })),
    }))
  },
}
