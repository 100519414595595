import Vue from 'vue'
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  // Tooltip,
  // Menu,
} from 'floating-vue'

import 'floating-vue/dist/style.css'

// Directives
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopper)

// Components
// Vue.component('VTooltip', Tooltip)
Vue.component('VDropdown', Dropdown)
// Vue.component('VMenu', Menu)
