export const state = () => ({
  showAll: false,
  selectedConsultantId: null,
})

export const mutations = {
  toggleView(state) {
    state.showAll = !state.showAll
  },
  setSelectedConsultantId(state, consultantId) {
    state.selectedConsultantId = consultantId
  },
}
