import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import * as rules from 'vee-validate/dist/rules'
import { parse, isBefore, isAfter, isValid, subDays } from 'date-fns'

import { removeApostroph } from '~/utils'

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend('required', rules.required)
  extend('min', rules.min)
  extend('max', rules.max)
  extend('email', rules.email)
  extend('max_value', rules.max_value)
  extend('numeric', {
    validate: (value) => {
      if (value === null || value === undefined || value === '') {
        return {
          valid: false,
        }
      }

      value = removeApostroph(value)

      value = Number(value)
      return {
        valid: !isNaN(value) && typeof value === 'number',
      }
    },
    message: '{_field_} darf nur numerische Zeichen enthalten',
  })
  extend('decimal', {
    validate: (value, params) => {
      const decimals = params[0] ? parseInt(params[0]) : '*'
      if (value === null || value === undefined || value === '') {
        return {
          valid: false,
        }
      }

      value = removeApostroph(value)
      if (decimals === 0) {
        return {
          valid: /^-?\d*$/.test(value),
        }
      }
      const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
      const regex = new RegExp(
        `^[-+]?\\d*(\\.\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
      )

      return {
        valid: regex.test(value),
      }
    },
    message: (field, params) => {
      const decimals = params[0] ? parseInt(params[0]) : null

      if (decimals === 0) {
        return `${field} muss eine ganzzahlige Zahl sein`
      }
      if (!decimals) {
        return `${field} muss eine Dezimalzahl sein`
      }

      return `${field} muss eine Dezimalzahl sein mit maximal ${decimals} Nachkommastellen`
    },
  })
  extend('date', {
    validate(value) {
      if (!value) {
        return true
      }

      const match = value.match(
        /([0][1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.([1-2][0-9]{3})/
      )
      if (!match) {
        return false
      }
      if (match[0] !== match.input?.trim()) {
        return false
      }
      const date = parse(value, 'dd.MM.yyyy', new Date())
      return isValid(date)
    },
    message: 'Datumformat ist nicht korrekt',
  })
  extend('before', {
    validate(value, { beforeDate }) {
      if (!value) {
        return true
      }

      if (!beforeDate || beforeDate === 'today') {
        beforeDate = new Date()
      } else if (beforeDate === 'tomorrow') {
        beforeDate = subDays(new Date(), -1)
      } else {
        beforeDate = parse(beforeDate, 'dd.MM.yyyy', new Date())
      }

      const date = parse(value, 'dd.MM.yyyy', new Date())

      return isBefore(date, beforeDate)
    },
    params: ['beforeDate'],
    message: (field, values) => {
      if (values) {
        if (!values.beforeDate || values.beforeDate === 'today') {
          return 'Datum muss in der Vergangenheit liegen'
        }
        return `Datum muss vor ${values.beforeDate} sein`
      }
      return ''
    },
  })
  extend('after', {
    validate(value, { afterDate }) {
      if (!value) {
        return true
      }

      if (!afterDate || afterDate === 'today') {
        afterDate = new Date()
      } else if (afterDate === 'yesterday') {
        afterDate = subDays(new Date(), 1)
      } else {
        afterDate = parse(afterDate, 'dd.MM.yyyy', new Date())
      }
      const date = parse(value, 'dd.MM.yyyy', new Date())
      return isAfter(date, afterDate)
    },
    params: ['afterDate'],
    message: (field, values) => {
      if (values) {
        if (
          !values.afterDate ||
          values.afterDate === 'today' ||
          values.afterDate === 'yesterday'
        ) {
          return 'Datum muss in der Zukunft liegen'
        }
        return `Datum muss nach ${values.afterDate} sein`
      }
      return ''
    },
  })
  extend('confirmValue', {
    validate: (value, params) => {
      if (value === null || value === undefined || value === '') {
        return {
          valid: false,
        }
      }

      const confirmation = params[0] ? params[0] : null

      return {
        valid: value === confirmation,
      }
    },
    message: `Falsche Bestätigung für {_field_} eingegeben`,
  })
})

localize('de', de)

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.config.productionTip = false
