export const state = () => ({
  users: [],
})

export const actions = {
  async loadUsers({ commit }) {
    const { data } = await this.$axios.get(`users`)
    commit('setUsers', data.data)
  },
}

export const mutations = {
  setUsers(state, users) {
    state.users = users
  },
}

export const getters = {
  consultants: (state) =>
    state.users.map((u) => ({
      ...u,
      name: `${u.first_name} ${u.last_name}`,
    })),
}
