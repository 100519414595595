import isEqual from 'lodash/isEqual'
import { format } from 'date-fns'
import { encodeQueryParams } from '~/utils'

let loadAllCancelTokenSource = null
let statisticsCancelTokenSource = null
export const state = () => ({
  filterCustomers: null,
  filterRecruitmentSources: null,
  filterCreation: null,
  filterTrancheValidFrom: null,
  lastFilter: null,
  statistics: null,
  ordering: {
    column: 'customers.created_at',
    dir: 'desc', // or 'desc'
  },
  pagination: {
    currentPage: 1,
    lastPage: 1,
  },
  customer: null,
  customers: [],
  customersMeta: {},
  customerOptions: [],
  lastUpdatedCustomers: [],
})

export const actions = {
  async loadAll({ commit, state, getters }) {
    let page = state.pagination.currentPage

    const filter = getters.getFilterParams
    if (!isEqual(state.lastFilter, filter)) {
      page = 1
    }

    const params = {
      page,
      column: state.ordering.column,
      dir: state.ordering.dir,
      ...filter,
    }
    const query = encodeQueryParams(params)

    if (loadAllCancelTokenSource) {
      loadAllCancelTokenSource.cancel()
    }

    loadAllCancelTokenSource = this.$axios.CancelToken.source()

    const { data } = await this.$axios.get(`leads?${query}`, {
      cancelToken: loadAllCancelTokenSource.token,
    })

    commit('setPagination', {
      currentPage: data.meta.current_page,
      lastPage: data.meta.last_page,
    })
    commit('setCustomers', data.data)
    commit('setCustomersMeta', data.meta)
    commit('setLastFilter', filter)
  },

  async load({ commit, state }, customerId) {
    const { data } = await this.$axios.$get(`leads/${customerId}`)
    commit('setCustomer', data)
    return data
  },

  async loadStatistics({ getters, commit, state }) {
    const query = encodeQueryParams(getters.getFilterParams)

    if (statisticsCancelTokenSource) {
      statisticsCancelTokenSource.cancel()
    }

    statisticsCancelTokenSource = this.$axios.CancelToken.source()

    const { data } = await this.$axios.$get(`customers/statistics?${query}`, {
      cancelToken: statisticsCancelTokenSource.token,
    })
    commit('setCustomerStatistics', data)
  },

  async updateOrdering({ commit, dispatch }, ordering) {
    commit('setOrdering', ordering)
    await dispatch('loadAll')
  },

  async changePage({ state, commit, dispatch }, nextPage) {
    const pagination = { ...state.pagination }
    pagination.currentPage = nextPage
    commit('setPagination', pagination)
    await dispatch('loadAll')
  },

  async loadCustomerOptions({ commit }, filters) {
    const params = {
      page: 1,
      ...filters,
    }
    const query = encodeQueryParams(params)

    const { data } = await this.$axios.get(`customers?${query}`)
    commit('setCustomerOptions', data.data)
  },
}

export const mutations = {
  setCustomers(store, customers) {
    store.customers = customers
  },
  setCustomersMeta(store, meta) {
    store.customersMeta = meta
  },
  setCustomer(store, customer) {
    store.customer = customer
      ? {
          ...customer,
          contacts: customer.contacts?.map((contact) => ({
            ...contact,
            selected: contact.selected || false,
          })),
        }
      : null
  },
  setCustomerStatistics(store, statisticData) {
    store.statistics = statisticData
  },
  setFilterCustomers(state, value) {
    state.filterCustomers = value
  },
  setFilterRecruitmentSources(state, value) {
    state.filterRecruitmentSources = value
  },
  setFilterCreation(state, value) {
    state.filterCreation = value
  },
  setFilterTrancheValidFrom(state, value) {
    state.filterTrancheValidFrom = value
  },
  setOrdering(store, ordering) {
    store.ordering = ordering
  },
  setPagination(store, pagination) {
    store.pagination = pagination
  },
  setCustomerOptions(store, customers) {
    store.customerOptions = customers
  },
  setLastUpdatedCustomers(state, customers) {
    state.lastUpdatedCustomers = customers
  },
  setLastFilter(state, lastFilterObject) {
    state.lastFilter = lastFilterObject
  },

  setLoadAllCancelTokenSource(state, token) {
    state.loadAllCancelTokenSource = token
  },

  setStatisticsCancelTokenSource(state, token) {
    state.statisticsCancelTokenSource = token
  },
}

export const getters = {
  getFilterParams(state) {
    const filter = {
      filterCustomers: state.filterCustomers,
      filterRecruitmentSources: state.filterRecruitmentSources?.value,
    }

    if (state.filterCreation?.start) {
      filter.filterCreationStart = format(
        state.filterCreation.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterCreation?.end) {
      filter.filterCreationEnd = format(state.filterCreation?.end, 'yyyy-MM-dd')
    }

    if (state.filterTrancheValidFrom?.start) {
      filter.filterTrancheValidFromStart = format(
        state.filterTrancheValidFrom.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheValidFrom?.end) {
      filter.filterTrancheValidFromEnd = format(
        state.filterTrancheValidFrom.end,
        'yyyy-MM-dd'
      )
    }

    const cleanFilters = Object.fromEntries(
      Object.entries(filter)
        .filter(([_, v]) => v !== undefined)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== '')
    )

    return cleanFilters
  },

  getFilterValues(state) {
    const filter = {
      filterCustomers: state.filterCustomers,
      filterRecruitmentSources: state.filterRecruitmentSources?.label,
    }

    if (state.filterCreation?.start) {
      filter.filterCreationStart = format(
        state.filterCreation.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterCreation?.end) {
      filter.filterCreationEnd = format(state.filterCreation?.end, 'yyyy-MM-dd')
    }

    if (state.filterTrancheValidFrom?.start) {
      filter.filterTrancheValidFromStart = format(
        state.filterTrancheValidFrom.start,
        'yyyy-MM-dd'
      )
    }

    if (state.filterTrancheValidFrom?.end) {
      filter.filterTrancheValidFromEnd = format(
        state.filterTrancheValidFrom.end,
        'yyyy-MM-dd'
      )
    }

    const cleanFilters = Object.fromEntries(
      Object.entries(filter)
        .filter(([_, v]) => v !== undefined)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== '')
    )

    return cleanFilters
  },

  customerOptions(state) {
    return state.customerOptions.map((customer) => ({
      label: customer.name,
      name: customer.name,
      value: customer.id,
      id: customer.id,
    }))
  },
  customer: (state) => state.customer,
  customers: (state) => state.customers,
}
